// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';
import FishOkWithCircle from '../../assets/images/views/areas/fish_ok_with_circle.svg';
import FishNgWithCircle from '../../assets/images/views/areas/fish_ng_with_circle.svg';

type Props = {
  area: Area,
  active: boolean,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
};

const K_WIDTH = 40;
const K_HEIGHT = 40;

export default class AreaMarker extends React.Component<Props> {
  render() {
    const { area, active } = this.props;

    return (
      <div
        className="marker"
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {active && (
          <div className="balloon">
            <a href={`/areas/${area.id}`} className="media">
              <div className="media-body">
                <h4 className="media-heading">{area.name}</h4>
                <div className="text-muted row">
                  {area.is_banned && (
                    <div className="col-5">
                      <span className="fish-forbidden-label">
                        <span className="fish-forbidden-text">釣り禁止</span>
                      </span>
                    </div>
                  )}
                  {!area.is_banned &&
                    area.summed_children_results_count != null && (
                      <div className="col-5">
                        <i className="icon-app f-17 mr-5" />
                        {area.summed_children_results_count}
                      </div>
                    )}
                  {!area.is_banned && area.latest_caught_at != null && (
                    <div className="col-7 text-right">
                      <i className="fa fa-clock-o fa-fw mr-5" />
                      {area.latest_caught_at.fromNow(true)}前
                    </div>
                  )}
                </div>
              </div>
            </a>
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            width: K_WIDTH,
            height: K_HEIGHT,
            left: -K_WIDTH / 2,
            top: -K_HEIGHT / 2,
          }}
        >
          <div>
            <img
              src={
                area.is_banned && area.is_banned
                  ? FishNgWithCircle
                  : FishOkWithCircle
              }
              width={K_WIDTH}
              height={K_WIDTH}
            />
            <span
              className="text-tuna4"
              style={{
                whiteSpace: 'nowrap',
                fontSize: '11px',
                fontWeight: '600',
                textShadow:
                  'white 1.2px 1.2px 0, white -1.2px -1.2px 0, white -1.2px 1.2px 0, white 1.2px -1.2px 0, white 0px 1.2px 0, white  0-1.2px 0, white -1.2px 0 0, white 1.2px 0 0',
              }}
            >
              {area.name}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
