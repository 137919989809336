// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import HeaderMobile from '../layouts/HeaderMobile';
import Loader from './Loader';
import { IndexBase } from './IndexBase';
import type { Props as BaseProps, State as BaseState } from './IndexBase';
import LocationSearchInput from './LocationSearchInput';
import GoogleMap from 'google-map-react';
import AreaMarker from './AreaMarker';
import FishOk from '../../assets/images/views/areas/fish_ok.svg';
import FishNg from '../../assets/images/views/areas/fish_ng.svg';

type Props = BaseProps & {
  logoIcon: string,
  fdl: ?string,
};

type State = BaseState & {
  search: boolean,
  activeTab: string,
};

const TAB_LIST = 'list';
const TAB_MAP = 'map';

export default class IndexMobileView extends IndexBase<Props, State> {
  searchWidth: number;
  mapHeight: number;
  googleMap: GoogleMap;

  onClickSearch = () => {
    this.setState({ search: true });
  };

  onClickSearchClose = () => {
    this.setState({ search: false });
  };

  onMouseLeave = () => {
    this.setState({ activeArea: null });
  };

  onLoadMore = () => {
    const { isLoading, hasNextPage, activeTab } = this.state;

    if (!isLoading && hasNextPage && activeTab === TAB_LIST) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchAreas()
      );
    }
  };

  onSearch = (lat: number, lng: number) => {
    this.onChangeAddress(lat, lng);
    this.googleMap.map_.panTo({ lat: lat, lng: lng });
  };

  constructor(props: Props) {
    super(props);

    this.searchWidth = window.innerWidth - 70;
    this.mapHeight = window.innerHeight - (50 + 50);

    this.state = {
      areas: [],
      activeArea: null,
      position: this.defaultPosition,
      isLoading: true,
      page: 1,
      hasNextPage: true,
      address: '',
      search: false,
      activeTab: TAB_LIST,
    };
  }

  render() {
    const { apiKey, logoIcon, userId, fdl } = this.props;
    const { areas, activeArea, hasNextPage, search, activeTab } = this.state;

    return (
      <div>
        {search ? (
          <HeaderMobile
            active="areas"
            logoIcon={logoIcon}
            userId={userId}
            menu={false}
            fdl={fdl}
          >
            <div className="d-flex">
              <LocationSearchInput
                address={this.state.address}
                onSearch={this.onSearch}
                onSearchClose={this.onClickSearchClose}
                width={this.searchWidth}
              />
            </div>
          </HeaderMobile>
        ) : (
          <HeaderMobile
            active="areas"
            logoIcon={logoIcon}
            userId={userId}
            fdl={fdl}
          >
            <div className="title" onClick={this.onClickSearch}>
              エリアを探す
            </div>
            <div className="navbar-btn" onClick={this.onClickSearch}>
              <i className="ion-ios-search" />
            </div>
          </HeaderMobile>
        )}
        <ul className="nav tab-nav tn-justified">
          <li className={activeTab === TAB_LIST ? 'active' : ''}>
            <a
              href="#area_list"
              data-toggle="tab"
              onClick={() => this.setState({ activeTab: TAB_LIST })}
            >
              リスト
            </a>
          </li>
          <li className={activeTab === TAB_MAP ? 'active' : ''}>
            <a
              href="#areas_map"
              data-toggle="tab"
              onClick={() => this.setState({ activeTab: TAB_MAP })}
            >
              地図
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="area_list">
            <div className="listview lv-bordered lv-lg">
              <InfiniteScroll
                pageStart={0}
                loadMore={this.onLoadMore}
                hasMore={hasNextPage}
                loader={<Loader key={0} />}
                className="lv-body"
              >
                {areas.map(area => (
                  <a
                    href={`/areas/${area.id}`}
                    key={area.id}
                    className="lv-item"
                    onMouseEnter={() => this.setState({ activeArea: area })}
                    onMouseLeave={() => this.setState({ activeArea: null })}
                  >
                    <div className="media row">
                      <div className="text-center col-3">
                        <img
                          src={
                            area.is_banned && area.is_banned ? FishNg : FishOk
                          }
                          width={64}
                          height={64}
                        />
                      </div>
                      <div className="media-body col-9 row">
                        <h4 className="col-12" style={{ marginTop: '3px' }}>
                          {area.name}
                        </h4>
                        <div className="text-muted col-12">
                          <div className="row">
                            <div className="col-5">
                              {!area.is_banned &&
                                area.summed_children_results_count != null && (
                                  <div className="text-muted">
                                    <i className="icon-app f-17 mr-5" />
                                    {area.summed_children_results_count}
                                  </div>
                                )}
                            </div>
                            <div className="col-7 text-right">
                              {!area.is_banned &&
                                area.latest_caught_at != null && (
                                  <div className="text-muted">
                                    <i className="fa fa-clock-o fa-fw mr-5" />
                                    {area.latest_caught_at.fromNow(true)}前
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {area.is_banned && (
                            <div>
                              <span className="fish-forbidden-label">
                                <span
                                  className="fish-forbidden-text"
                                  style={{ fontSize: '11px' }}
                                >
                                  釣り禁止
                                </span>
                              </span>
                            </div>
                          )}
                          {!area.is_banned && area.children_names != null && (
                            <div className="tags">
                              {area.children_names.map(child_name => (
                                <span
                                  className="badge badge-light"
                                  style={{ marginRight: '1px' }}
                                  key={child_name}
                                >
                                  {child_name}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <div className="tab-pane" id="areas_map">
            <div
              style={{
                width: '100%',
                height: this.mapHeight,
              }}
            >
              <GoogleMap
                ref={googleMap => (this.googleMap = googleMap)}
                bootstrapURLKeys={{ key: apiKey }}
                defaultCenter={[
                  this.defaultPosition.coords.latitude,
                  this.defaultPosition.coords.longitude,
                ]}
                defaultZoom={11}
                options={this.mapOptions}
                onChange={this.onChangeMap}
              >
                {areas.map(area => (
                  <AreaMarker
                    key={area.id}
                    lat={area.lat}
                    lng={area.lng}
                    area={area}
                    active={activeArea != null && activeArea.id === area.id}
                    onMouseEnter={() => this.setState({ activeArea: area })}
                    onMouseLeave={this.onMouseLeave}
                  />
                ))}
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
